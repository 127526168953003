.loading-screen {
    z-index: 1000;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Helvetica, 'sans-serif';
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
  }
  
  .loading-screen span {
    transition: none !important;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 10px 0 0 0 !important;
  }
  
  [data-theme="dark"] .loading-screen {
    color: #92929F;
  }
  
  [data-theme="dark"] .loading-screen span {
    color: #92929F;
  }
  
  .loading-screen-spinner {
    width: 70px;
    height: 70px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    animation: loading-screen-spinner-animation 1s infinite linear;
  }
  
  .loading-screen-spinner::before,
  .loading-screen-spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
  }
  
  .loading-screen-spinner::before {
    border-color: #00bce0 #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
  }
  
  .loading-screen-spinner::after {
    margin: 8.9px;
  }
  
  @keyframes loading-screen-spinner-animation {
    100% {
      transform: rotate(1turn);
    }
  }